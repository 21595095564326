
  import { Component, Vue, Watch } from "vue-property-decorator";
  import {
    readAdminUsersSearchResults,
    readUsersSearchOptions,
    readUsersSearchQuery,
  } from "@/store/admin/getters";
  import { debounce } from "@/utils";
  import { dispatchSearchUsers } from "@/store/admin/actions";
  import {
    commitSetUsersSearchOptions,
    commitSetUsersSearchQuery,
  } from "@/store/admin/mutations";
  import { apiUrl } from "@/env";

  const debouncedDispatchSearchUsers = debounce(dispatchSearchUsers, 300);

  @Component
  export default class AdminUsers extends Vue {
    public headers = [
      {
        text: "Actions",
        value: "actions",
      },
      {
        text: "First Name",
        sortable: true,
        value: "first_name",
        align: "left",
      },
      {
        text: "Last Name",
        sortable: true,
        value: "last_name",
        align: "left",
      },
      {
        text: "Email",
        sortable: true,
        value: "email",
        align: "left",
      },
      {
        text: "Status",
        sortable: true,
        value: "status",
        align: "left",
      },
      {
        text: "Role",
        sortable: true,
        value: "role",
        align: "left",
      },
      {
        text: "Country",
        sortable: true,
        value: "country",
        align: "left",
      },
      {
        text: "Province",
        sortable: true,
        value: "province",
        align: "left",
      },
      {
        text: "Town",
        sortable: true,
        value: "town",
        align: "left",
      },
      {
        text: "Phone",
        sortable: true,
        value: "phone",
        align: "left",
      },
      {
        text: "Practitioner Number",
        value: "practitioner_number",
        align: "left",
      },
    ];

    loading = false;
    options = { page: 1 };
    searchQuery: string | null = null;
    prevRoute = null;
    meta = { fromHistory: false };

    get usersResults() {
      return readAdminUsersSearchResults(this.$store);
    }

    get users() {
      return this.usersResults.users;
    }

    get totalUsers() {
      return this.usersResults.pagination.total;
    }

    async refreshData() {
      this.loading = true;
      await debouncedDispatchSearchUsers(this.$store, {
        options: this.options,
        searchQuery: this.searchQuery,
      });
      this.loading = false;
    }

    @Watch("options")
    async onOptionsChange() {
      this.refreshData();
    }

    @Watch("searchQuery")
    async onSearchQueryChange() {
      this.options.page = 1;
      this.refreshData();
    }

    public async mounted() {
      const storedOptions = readUsersSearchOptions(this.$store);
      if (storedOptions) {
        this.options = storedOptions;
      }
      const storedQuery = readUsersSearchQuery(this.$store);
      if (storedQuery) {
        this.searchQuery = storedQuery;
      }
      await this.refreshData();
    }

    beforeRouteLeave(to, from, next) {
      commitSetUsersSearchOptions(this.$store, this.options);
      commitSetUsersSearchQuery(this.$store, this.searchQuery);
      next();
    }

    routeEditUser(id: string) {
      this.$router.push({ name: "main-admin-users-edit", params: { id } });
    }

    downloadUsers() {
      window.open(`${apiUrl}/api/v1/users/download/`, "_blank");
    }
  }
